<template>
  <div>
    <v-app-bar app :style="appBarStyleObj" tile height="62" width="100%" class="compactlist-page">
      <div class="d-flex pt-1 px-3 pb-0" style="width: 100%;">
        <template v-if="!payload.hideHeader">
          <template v-if="selectionMode">
            <v-icon dark class="mx-1"> mdi-alert-box </v-icon>
            <v-spacer></v-spacer>
            <v-btn color="primary" class="text-capitalize mr-1" @click="makeSelection({ iscancel: true })"> {{ $t('cancel') }} </v-btn>
            <v-btn outlined dark :color="$vuetify.theme.dark ? 'error' : ''" class="text-capitalize" @click="payload.isTickets ? payload.menuActions('delete') : deleteRecord()"
            v-if="selections.length || selectedTickets.length">
              {{ $t('delete') }}
              ({{ selections.length || selectedTickets.length }})
            </v-btn>
            <v-menu offset-y v-if="payload.isTickets && payload.hasPermission && !payload.fromSubModule && !moduleName">
                <template #activator="{ on }">
                  <v-btn v-on="on" dark icon small id="ticket_actions" elevation="0" class="ml-3 mt-1">
                    <v-icon> mdi-dots-vertical </v-icon>
                  </v-btn>
                </template>
                <v-list dense class="py-1">
                  <template v-for="(item, index) in menuList">
                    <v-list-item v-if="item.isHeader" :key="index" @click="payload.menuActions(item.value)">
                      <v-list-item-icon class="ma-0 my-2 mr-2">
                        <v-icon v-text="item.icon" size="22" left></v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                  </template>
                </v-list>
              </v-menu>
          </template>
          <template v-else-if="payload.hasSearch && enableSearch && !selectionMode">
            <v-layout row wrap align-center class="ma-0">
              <v-flex xs1 class="text-center">
                <v-btn color="error" fab @click="enableSearch = false; updateList({ clear: true })" x-small elevation="0">
                  <v-icon dark> mdi-magnify-close </v-icon>
                </v-btn>
              </v-flex>
              <v-flex xs11>
                <v-text-field dark outlined :label="$t('search')" class="pa-0 ml-3" v-model="searchTerm" hide-details dense
                clearable @click:clear="updateList({ clear: true })" autofocus append-icon="mdi-magnify" @keydown="preventSpecialCharacters($event)"
                @click:append="updateList" @keydown.enter="updateList" :loading="payload.loading"></v-text-field>
              </v-flex>
            </v-layout>
          </template>
          <template v-else-if="searchTickets && payload.isTickets && !selectedTickets.length">
            <v-btn color="error" fab @click="searchTickets = false; searchTicketTerm=''" x-small elevation="0">
              <v-icon dark> mdi-magnify-close </v-icon>
            </v-btn>
            <v-text-field :label="$t('search')" class="pa-0 mx-3" v-model="searchTicketTerm" @click:append="searchTicket" @keydown.enter="searchTicket" clearable
            @click:clear="searchTicket({ clear: true })" @keydown="preventSpecialCharacters($event)" outlined dark hide-details dense autofocus append-icon="mdi-magnify"></v-text-field>
          </template>
          <template v-else>
            <template>
              <v-btn v-if="payload.hasPagination" x-small fab @click="previousList" :disabled="payload.pagination.page === 1">
                <v-icon> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn v-else-if="payload.showBackBtn" x-small fab @click="payload.showBackBtn()" elevation="0" color="transparent" dark>
                <v-icon> mdi-arrow-left </v-icon>
              </v-btn>
            </template>
            <v-btn :color="$vuetify.theme.dark ? '' : systemDetails.themecolor" fab x-small v-if="payload.isSubmodule" @click="showActionView()" elevation="0">
              <v-icon :color="$vuetify.theme.dark ? '' : systemDetails.textcolor">mdi-arrow-left</v-icon>
            </v-btn>
            <v-btn :color="$vuetify.theme.dark ? '' : systemDetails.themecolor" v-if="payload.hasSearch && !enableSearch" fab
            @click="enableSearch = true" x-small elevation="0">
              <v-icon :color="$vuetify.theme.dark ? '' : systemDetails.textcolor" size="20"> mdi-magnify </v-icon>
            </v-btn>
            <v-btn :color="$vuetify.theme.dark ? '' : systemDetails.themecolor" v-if="payload.addClickHandler && payload.hasPermission" fab id="newbtn" @click="payload.addClickHandler"
            fixed bottom right elevation="9" small class="mb-16 mr-n1" :outlined="$vuetify.theme.dark">
              <v-icon :color="$vuetify.theme.dark ? '' : systemDetails.textcolor"> mdi-plus </v-icon>
            </v-btn>
            <v-btn v-else-if="payload.isTickets && moduleName" icon dark small :color="systemDetails.themecolor" :style="`color: ${systemDetails.textcolor}`" @click="$root.$emit('hideSubmoduleView')">
              <v-icon> mdi-arrow-left </v-icon>
            </v-btn>
            <v-btn :color="$vuetify.theme.dark ? '' : systemDetails.themecolor" v-if="payload.isTickets && !searchTickets" fab
            @click="searchTickets = true" x-small elevation="0" class="ml-1">
              <v-icon :color="$vuetify.theme.dark ? '' : systemDetails.textcolor" size="20"> mdi-magnify </v-icon>
            </v-btn>
            <v-btn :color="$vuetify.theme.dark ? '' : systemDetails.themecolor" v-if="payload.isCustomModule && payload.relateClickHandler && payload.accesscontrol.edit"
            fab x-small class="ml-3" id="relatebtn" @click="payload.relateClickHandler" elevation="0">
              <v-icon :color="$vuetify.theme.dark ? '' : systemDetails.textcolor">mdi-swap-horizontal</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-menu offset-y v-if="!payload.isTickets && !payload.isNotes && !payload.isChecklist && !payload.isSubmodule">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" small icon :color="systemDetails.textcolor" class="mt-1 mr-2">
                  <!-- <v-icon>mdi-view-headline</v-icon> -->
                  <v-icon>mdi-view-carousel</v-icon>
                </v-btn>
              </template>
              <v-list dense width="150" min-width="200" max-width="200">
                <v-list-item-group :value="listOfFilterViews.findIndex(x => x._id === activeFilterView._id)" mandatory>
                  <v-list-item v-for="(item, index) in listOfFilterViews" :key="index" @click="changeActivePageView(item)" class="pl-3 pr-2">
                    <v-list-item-content>
                      <v-list-item-subtitle style="max-width: 150px;" class="body-2 text-truncate">
                        {{item.name}}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn v-if="item._id !== listOfFilterViews[0]._id" x-small icon @click.stop="$emit('editPageView', item._id)" color="primary">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list-item-group>
                  <v-list-item @click="$emit('createPageView')" class="pl-3 pr-2">
                    <!-- <v-btn x-small icon>
                      <v-icon>mdi-plus-circle-outline</v-icon> -->
                      <!-- {{ 'add' }} -->
                    <!-- </v-btn> --> <v-spacer></v-spacer>
                    <v-btn text color="primary" class="text-capitalize px-2">Create new
                      <v-icon size="18" class="ml-1 mt-1">mdi-plus-circle-outline</v-icon>
                    </v-btn>
                  </v-list-item>
              </v-list>
            </v-menu>
             <!-- <div class="ml-4 mt-1">
              <v-icon :color="systemDetails.textcolor" size="24" class="mb-1"> {{ payload.moduleIcon }} </v-icon>
              <v-badge inline :value="!!total" color="grey lighten-2">
                <span :class="['body-1 mx-2 font-weight-bold']" :style="`color: ${ systemDetails.textcolor || 'white' };`">
                  {{ payload.isNotes ? $t('notes') : (payload.moduleName || payload.moduleTitle) }}
                </span>
                <template #badge>
                  <span class="black--text"> {{ total }} </span>
                </template>
              </v-badge>
            </div> -->
            <div class="ml-4 mt-1">
              <v-icon :color="systemDetails.textcolor" size="24" class="mb-1"> {{ payload.moduleIcon }} </v-icon>
              <span :class="['body-1 mx-2 font-weight-bold']" :style="`color: ${ systemDetails.textcolor || 'white' };`">
                  {{ payload.isNotes ? $t('notes') : (payload.moduleName || payload.moduleTitle) }}
              </span>
              <span class="text-subtitle-2" v-if="!showCount && !countLoading" :style="`color: ${ systemDetails.textcolor || 'white' };`"
                  @click="getTotalCount()">
                <small> {{ $t('showCount') }} </small>
              </span>
              <v-progress-circular indeterminate v-if="countLoading" size="15" color="#0D47A1"></v-progress-circular>
              <v-badge v-if="showCount && !countLoading" inline color="grey lighten-2">
                <template #badge>
                  <span class="black--text" :style="`color: ${ systemDetails.textcolor || '#000000 !important' };`" style="cursor: pointer; !important">{{ `${total}` }} </span>
                </template>
              </v-badge>
            </div>
            <v-spacer></v-spacer>
             <template v-if="payload.isTickets ? !payload.loading && payload.list.length : ((payload.isNotes || payload.isChecklist) && (!payload.loading && payload.list.length)) ? (payload.accesscontrol && payload.accesscontrol.delete) : payload.isSubmodule ? (!payload.loading && payload.list.length && payload.list.some(({ accesscontrol }) => accesscontrol && accesscontrol.delete) && payload.accesscontrol.delete && payload.accesscontrol.edit) : (!payload.loading && payload.list.length && payload.list.some(({ accesscontrol }) => accesscontrol && accesscontrol.delete))">
              <v-btn fab class="mr-1" dark x-small :color="$vuetify.theme.dark ? '' : systemDetails.themecolor" elevation="0" @click="makeSelection">
                <v-icon :color="systemDetails.textcolor" size="20"> mdi-square-edit-outline </v-icon>
              </v-btn>
            </template>
            <template v-if="payload.hasFilter">
              <v-badge :color="systemDetails.textcolor" class="custom-badge" :content="conditions.length" overlap v-if="(conditions && conditions.length)" offset-x="12">
                <template v-slot:badge>
                  <span :style="`color: ${systemDetails.themecolor}`">{{ conditions.length }}</span>
                </template>
                <v-btn fab class="mr-1" dark x-small :color="$vuetify.theme.dark ? '' : systemDetails.themecolor" @click="toggleFilter" elevation="0">
                  <v-icon :color="systemDetails.textcolor"> mdi-filter </v-icon>
                </v-btn>
              </v-badge>
              <v-btn fab class="mr-1" dark x-small :color="$vuetify.theme.dark ? '' : systemDetails.themecolor" @click="toggleFilter" elevation="0" v-else>
                <v-icon :color="systemDetails.textcolor" size="20"> mdi-filter </v-icon>
              </v-btn>
            </template>
            <v-btn fab class="mr-1" dark x-small :color="$vuetify.theme.dark ? '' : systemDetails.themecolor" v-if="payload.refreshHandler" @click="payload.refreshHandler()"
            elevation="0">
              <v-icon :color="systemDetails.textcolor"> mdi-reload </v-icon>
            </v-btn>
            <template v-if="(payload.loading || payload.list.length) && !payload.isTickets && !payload.isNotes && !payload.isChecklist">
              <v-menu left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark icon small v-bind="attrs" v-on="on" style="margin-top: 2px;">
                    <v-icon size="19" :color="systemDetails.textcolor">mdi-sort</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                   <v-list-item-group v-model="payload.sortByField" color="primary">
                    <v-list-item v-for="(item, i) in ((userDetails.isadmin) ? payload.items : (payload.items.filter((field) => (field.name !== 'create_for' && field.label !== 'Event_create_for'))))"
                    :key="i" @click="sortingHandler(i)">
                      <v-list-item-title> {{ $t(item.label) }} </v-list-item-title>
                      <v-list-item-icon v-if="payload.sortByField === i">
                        <v-icon> {{ payload.sortBy === 1 ? 'mdi-sort-alphabetical-ascending': 'mdi-sort-alphabetical-descending' }}</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </template>
            <v-btn v-if="payload.hasPagination" x-small fab @click="nextList" :disabled="hasNoNext || payload.hasNoNext" elevation="0">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </template>
        </template>
      </div>
    </v-app-bar>
    <v-card flat tile height="calc(var(--var) * 100 - 118px)">
      <v-card-text :class="[payload.hasPagination ? '' : 'custom_scroll_style', 'pa-1 pt-0', $vuetify.theme.dark ? '' : 'app-bg-color']" @scroll="onScrollListListener">
        <div class="text-center" v-if="payload.loading">
          <v-container fluid class="px-1 pb-1">
            <v-row no-gutters>
              <v-col v-for="(item) in 12" :key="item" cols="12" sm="6" lg="3">
                <v-sheet class="mb-1">
                  <v-skeleton-loader class="mx-auto" type="list-item"></v-skeleton-loader>
                </v-sheet>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <template v-if="payload.isTickets">
          <v-list :style="`background-color: ${$vuetify.theme.dark ? 'black' :''}`" two-line color="rgb(247, 247, 247)">
              <template v-for="(record, index) in payload.list">
               <v-list-item :key="`${index}_record`" :value="record" class="custom_list_style px-3 py-0 pl-5" :style="`background-color: ${$vuetify.theme.dark ? 'black' :''}`"
                  @click.stop="selectionMode ? pushOrPopSelection(index, true) : payload.viewClickHandler(record)">
                  <!-- <template #default="{ active }"> -->
                    <v-list-item-avatar size="25" class="avatar_custom_margin">
                      <v-checkbox v-if="selectionMode" dense hide-details readonly class="mt-n1 ml-1" light
                          :input-value="selectedTickets.includes(record)" :key="index"></v-checkbox>
                      <v-icon v-else size="20" :color="$vuetify.theme.dark ? '' : systemDetails.themecolor" v-text="payload.moduleIcon"></v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content class="pa-3 ml-2" :style="`color: ${$vuetify.theme.dark ? 'white' :''}`">
                      <v-row>
                        <template v-for="(item, index) in payload.items">
                          <v-col class="pa-0 mt-3 font-weight-bold" cols="8" :key="index" v-if="index === 0">{{ record[item.value] }}</v-col>
                          <v-col class="pa-0 mt-2 text-right" cols="4" :style="`color: ${record.statuscolor}`" :key="index" v-else-if="index === 1">{{ record[item.value] }}</v-col>
                          <v-col class="pa-0 mt-0 mb-1" cols="12" :key="index" v-else>{{ record[item.value] }}</v-col>
                        </template>
                      </v-row>
                    </v-list-item-content>
                  <!-- </template> -->
                </v-list-item>
              </template>
            <!-- </v-list-item-group> -->
          </v-list>
        </template>
        <template v-else-if="payload.list.length && !payload.loading">
          <template>
            <!-- <v-card outlined color="#e8eaf6" class="mb-1" :key="index" @click="payload.editHandler(record)"> -->
              <v-container fluid class="pa-1">
                <v-row :no-gutters="$vuetify.breakpoint.xsOnly" :class="!$vuetify.breakpoint.xsOnly ? 'pa-1 pt-2': ''">
                  <v-col sm="6" md="6" xs="12" cols="12" v-for="(record, index) in payload.list" :key="index" class="pt-1 pb-0">
                    <v-card outlined elevation="2" class="mb-1" :color="$vuetify.theme.dark ? '#1e1e1e' : '#fff'" @click.stop="selectionMode ? pushOrPopSelection(index) : payload.editHandler(record)">
                      <v-card-text :class="profileEnabled ? 'py-4' : 'py-6'">
                        <v-row align="center">
                          <v-col cols="12" :style="`background: ${ $vuetify.theme.dark ? '#1e1e1e' : '' }`">
                            <v-row align="center">
                              <v-col cols="2" lg="1" md="1" sm="2" :class="[selectionMode ? 'mr-n2' : '', 'pa-2 text-center']">
                                <template v-if="selectionMode && ((record.accesscontrol && record.accesscontrol.delete) || payload.isNotes || payload.isChecklist)">
                                  <v-checkbox dense hide-details  class="mt-n1 ml-1" @click.stop="" v-model="selections"
                                  :value="record._id" :key="index"></v-checkbox>
                                </template>
                                <template v-else>
                                  <v-avatar v-if="profileEnabled && !payload.isNotes" size="35" :color="record.profile ? '' : $vuetify.theme.dark ? 'orange' : 'orange'" rounded>
                                    <img v-if="record.profile" :src="record.profile" alt="Profile">
                                    <span v-else :class="['caption font-weight-medium', $vuetify.theme.dark ? '' : 'white--text']">
                                      {{ record.name ? record.name.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase() : '' }}
                                    </span>
                                  </v-avatar>
                                  <v-icon v-else size="20" :color="$vuetify.theme.dark ? '' : systemDetails.themecolor"> {{ payload.moduleIcon }} </v-icon>
                                </template>
                              </v-col>
                              <v-col :cols="payload.isSubmodule || payload.isChecklist ? '8' : '10'" md="11" sm="6" class="pa-1 pl-2 px-0">
                                <v-row no-gutters>
                                  <template v-if="payload.isNotes || payload.isChecklist">
                                    <v-col v-for="(item, index) in payload.items" :key="index" :cols="index === 0 ||  index === 1 ? 12 : 6"
                                      :class="index === 0 || index === 1 ? `body-2 ${ $vuetify.theme.dark ? '' : 'black--text' }` : 'caption font-weight-medium font-italic text-truncate'">
                                     <span class="d-inline-block text-truncate" style="max-width: 150px;"> {{ record[item.value] || '--' }} </span>
                                    </v-col>
                                  </template>
                                  <template v-else>
                                    <template v-for="(item, index) in payload.items">
                                      <span :key="index" v-if="item.type === 3">
                                        <v-icon v-if="record[item.name]" size="17" class="mr-1" color="success"> mdi-checkbox-marked-circle </v-icon>
                                        <v-icon v-else size="17" class="mr-1" color="error"> mdi-close-circle </v-icon>
                                      </span>
                                      <v-col v-else :key="index" :cols="index === 0 ||  index === 1 ? 12 : 7"
                                      :class="index === 0 ? `body-2 ${ $vuetify.theme.dark ? '' : 'black--text' }` : index === 1 ? 'subtitle-2 grey--text text--darken-2' : 'caption font-weight-medium font-italic text-truncate'">
                                      <span class="d-inline-block text-truncate text-capitalize" style="max-width: 250px;"> {{ $t(item.label) }} : {{ record[item.name] || '--' }} </span>
                                      </v-col>
                                    </template>
                                  </template>
                                </v-row>
                              </v-col>
                              <v-col cols="2" class="pa-1" v-if="!selectionMode">
                                <v-btn icon color="primary" v-if="payload.isCustomModule && record.accesscontrol && record.accesscontrol.edit && payload.accesscontrol && payload.accesscontrol.edit"
                                  id="unrelatebtn" @click.stop="payload.unrelateClickHandler(record)" elevation="0" @touchstart.native.stop @touchmove.native.stop @touchend.native.stop @mouseup.native.stop
                                  @mousedown.native.stop>
                                  <v-icon> mdi-repeat-off </v-icon>
                                </v-btn>
                                <v-btn icon v-if="payload.isChecklist && payload.hasPermission" @click.stop="payload.downloadHandler(record)" color="primary" elevation="0" @touchstart.native.stop
                                  @touchmove.native.stop @touchend.native.stop @mouseup.native.stop @mousedown.native.stop>
                                  <v-icon> mdi-download</v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
          </template>
        </template>
        <v-alert prominent icon="mdi-information" text v-if="!payload.loading && !payload.loadingFields && payload.list.length === 0 && !payload.isTickets" :color="$vuetify.theme.dark ? '' : systemDetails.themecolor" class="ma-1 mt-2">
          <v-row align="center">
            <v-col class="grow"> {{ $t('noResults') }} </v-col>
          </v-row>
        </v-alert>
        <v-alert prominent icon="mdi-information" text v-if="!payload.loading && !payload.loadingFields && payload.list.length === 0 && payload.isTickets" :color="$vuetify.theme.dark ? '' : systemDetails.themecolor" class="ma-1 mt-2">
          <v-row align="center">
            <v-col class="grow"> {{ $t('noResults') }} </v-col>
          </v-row>
        </v-alert>
      </v-card-text>
    </v-card>
    <v-speed-dial bottom right fixed class="mb-12" v-if="!payload.isChecklist">
      <template v-slot:activator>
        <template v-if="payload.isNotes">
          <v-btn small :color="systemDetails && systemDetails.themecolor ? systemDetails.themecolor : 'primary'" :style="{ color: systemDetails.textcolor ? systemDetails.textcolor : 'white' }"
            fab dark v-if="payload.accesscontrol && payload.accesscontrol.edit"
            @click="payload.addClickHandler">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <template v-else-if="!payload.isTickets">
          <v-btn small :color="systemDetails && systemDetails.themecolor ? systemDetails.themecolor : 'primary'" :style="{ color: systemDetails.textcolor ? systemDetails.textcolor : 'white' }"
            fab dark v-if="payload.isCustomModule ? (payload.accesscontrol && payload.accesscontrol.add) : currentModule && currentModule.accesscontrol && currentModule.accesscontrol.add"
            @click="payload.addClickHandler">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
      </template>
    </v-speed-dial>
    <v-speed-dial fixed top left class="switch-list-btn mt-12" v-if="!payload.isTickets && !payload.isNotes && !payload.isChecklist && !payload.isSubmodule">
      <template v-slot:activator>
        <v-btn x-small fab dark :color="systemDetails.themecolor" class="mt-3 ml-2" @click="$emit('setCurrentView', { name: 'dashboard', value: 2 })">
          <v-icon :color="systemDetails.textcolor">mdi-view-dashboard-outline</v-icon>
        </v-btn>
      </template>
    </v-speed-dial>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: ['payload', 'total', 'conditions', 'moduleName', 'activeFilterView', 'listOfFilterViews', 'hasMoreRecords', 'countLoading', 'showCount'],
  data () {
    return {
      loading: false,
      totalCount: 0,
      perPage: 8,
      hasNoNext: false,
      page: 1,
      search: '',
      // total: 0,
      selectedTickets: [],
      scrollPaginationObj: {
        skip: 0,
        limit: 25
      },
      /* for notes pagination */
      currentPage: 1,
      hasNextPage: true,
      dontLoadList: false,
      searchTerm: '',
      moduleId: this.$route.params.id,
      profileEnabled: false,
      imageURL: process.env.VUE_APP_IMAGE_URL,
      domain: '',
      enableSearch: false,
      // long touch event handlers
      timer: null,
      touchduration: 1000, // length of time we want the user to touch before we do something
      selections: [],
      selectedRecord: null,
      // for the list view
      moduleFilters: [],
      fab: false,
      currentModule: {},
      searchTickets: false,
      searchTicketTerm: '',
      // showNotes: false,
      // deleteRecords: {},
      userHasScrolled: false,
      selectionMode: false
    }
  },
  computed: {
    /* for tickets */
    ...mapGetters(['userDetails', 'systemDetails', 'listOfModules']),
    menuList () {
      return [
        { title: this.$t('createTask'), value: 'createTask', icon: 'mdi-clipboard-check', isHeader: false },
        { title: this.$t('createTicket'), value: 'createTicket', icon: 'mdi-palette-swatch', isHeader: false },
        { title: this.$t('merge'), value: 'merge', icon: 'mdi-call-merge', isHeader: true },
        { title: this.$t('changeStatus'), value: 'changeStatus', icon: 'mdi-priority-low', isHeader: true },
        { title: this.$t('changePriority'), value: 'changePriority', icon: 'mdi-currency-sign', isHeader: true },
        { title: this.$t('assignTo'), value: 'assignTo', icon: 'mdi-account-circle', isHeader: true }
        // { title: this.$t('delete'), value: 'delete', icon: 'mdi-delete', isHeader: false }
      ]
    },
    appBarStyleObj () {
      if (this.selectionMode || this.selectedTickets.length) {
        return {
          background: this.$vuetify.theme.dark ? '' : '#ce2a2a'
        }
      } else if (this.payload.hasSearch && this.enableSearch && !this.selectionMode) {
        return {
          background: this.$vuetify.theme.dark ? '' : this.systemDetails.themecolor
        }
      } else {
        return {
          background: this.$vuetify.theme.dark ? '#181818' : this.systemDetails.themecolor
        }
      }
    }
  },
  watch: {
    search () {
      this.$eventBus.$emit('loadList', { page: this.page, search: this.search, itemsPerPage: this.perPage })
    },
    page: {
      handler (val) {
        if (!this.dontLoadList) {
          this.$eventBus.$emit('loadList', { page: val, search: this.search, itemsPerPage: this.perPage })
        }
      },
      deep: true
    },
    selectedTickets () {
      this.$emit('update-selected-tickets', this.selectedTickets)
    }
  },
  mounted () {
    this.currentModule = this.listOfModules.find(x => x._id === this.moduleId)
    if (this.currentModule) this.profileEnabled = this.currentModule.enableprofile
    this.domain = this.userDetails.domain
    const paginationStoredValue = this.payload.storeIndex ? this.$store.state.common[this.payload.storeIndex] : undefined
    const pagination = paginationStoredValue ? !this.$formatter.isEmptyObject(paginationStoredValue) ? this.$store.state.common[this.payload.storeIndex] : { itemsPerPage: this.perPage, page: 1 } : { itemsPerPage: this.perPage, page: 1 }
    this.page = pagination.page

    // let filtersHistory = localStorage.getItem('BusinessFlow365_filtersHistory')
    // filtersHistory = filtersHistory ? JSON.parse(filtersHistory) : []
    // filtersHistory = filtersHistory.find((filterItem) => filterItem.name === this.$route.params.name)
    // this.moduleFilters = filtersHistory || []

    this.$eventBus.$on('getCount', getCountParams => {
      const { totalCount, page, actionDelete } = getCountParams
      if (actionDelete && this.page !== page) {
        this.dontLoadList = true
        this.page = page
      }
      if (this.page === totalCount) this.hasNoNext = true
      else this.hasNoNext = false
      setTimeout(() => {
        this.dontLoadList = false
      }, 50)
    })
    this.$eventBus.$on('resetSelectedTickets', () => {
      this.selectedTickets = []
    })
    this.$eventBus.$on('changePage', (pageNo) => {
      this.changePage(pageNo)
    })
    this.$eventBus.$on('notes', (data) => {
      // this.showNotes = data
      this.selections = []
    })
    this.$eventBus.$on('selectionModeToggle', (data) => {
      this.selectionMode = data
    })
  },
  methods: {
    changeActivePageView (item) {
      this.$root.$emit('changeActiveFilterView', item)
      this.scrollPaginationObj = {
        skip: 0,
        limit: 25
      }
    },
    searchTicket ({ clear }) {
      if (clear) this.searchTicketTerm = ''
      this.$emit('search-term', this.searchTicketTerm)
    },
    showActionView () {
      this.$root.$emit('hideSubmoduleView')
    },
    onScrollListListener (ev) {
      this.userHasScrolled = true
      setTimeout(() => {
        this.userHasScrolled = false
      }, 1000)
      if (this.atBottom(ev.target)) {
        // if (this.payload.list.length !== this.total) {
        if (this.hasMoreRecords) {
          const model = {
            skip: this.payload.list.length,
            limit: this.payload.isTickets ? 5 : 25,
            searchterm: this.searchTerm
            // limit: this.payload.isTickets ? 5 : this.activeFilterView.appliedfilter.limit
          }
          if (JSON.stringify(model) !== JSON.stringify(this.scrollPaginationObj)) {
            this.scrollPaginationObj = model
            this.$eventBus.$emit('loadList', { ...model, islazyload: true })
          }
        }
      }
    },
    atBottom (ele) { // method to check whether the scroll has reached to the bottom
      var sh = ele.scrollHeight
      var st = ele.scrollTop
      var ht = ele.offsetHeight
      // if (ht === 0) return true
      if ((st + 1000) >= (sh - ht)) return true
      else return false
    },
    deleteRecord () {
      let modulename = (this.payload.moduleName || this.$route.params.name)
      if (this.payload.isNotes) modulename = 'notes'
      else if (this.payload.isChecklist) modulename = 'checklists'

      this.$root.$emit('showPermanentDeleteDialog', {
        ids: this.selections,
        modulename,
        oldDeleteMethod: ['notes', 'checklists'].includes(modulename),
        onResolve: () => {
          this.makeSelection({ iscancel: true })
          if (this.payload.isNotes) this.$parent.getNoteListHandler()
          else if (this.payload.isChecklist) this.$parent.getListHandler()
          else {
            this.$emit('delete', {
              page: 1,
              search: this.search,
              itemsPerPage: this.perPage,
              actionDelete: true
            })
          }
        }
      })
    },
    previousList () {
      this.page--
      this.checkIsNextEnabled()
    },
    nextList () {
      if (this.page === this.totalCount) this.hasNoNext = true
      else {
        this.page++
        this.hasNoNext = false
      }
    },
    checkIsNextEnabled () {
      if (this.page === this.totalCount) this.hasNoNext = true
      else {
        this.hasNoNext = false
      }
    },
    toggleFilter () {
      this.$eventBus.$emit('showHideFilter')
    },
    /* for notes pagination */
    prevPage () {
      if (this.currentPage > 1) {
        this.currentPage--
        this.changePage(this.currentPage)
      }
    },
    nextPage () {
      if (this.currentPage < this.numPages()) {
        this.currentPage++
        this.changePage(this.currentPage)
      }
    },
    changePage (page) {
      this.currentPage = page
      this.payload.list = []
      if (page < 1) page = 1
      if (page > this.numPages()) page = this.numPages()
      if (this.payload.completeList.length) {
        for (var i = (page - 1) * this.perPage; i < (page * this.perPage) && i < this.payload.completeList.length; i++) {
          this.payload.list.push(this.payload.completeList[i])
        }
      }
      if (page === 1) {
        this.hasNextPage = true
      }
      // eslint-disable-next-line
      if (page == this.numPages()) {
        this.hasNextPage = true
      } else {
        this.hasNextPage = false
      }
    },
    numPages () {
      return Math.ceil(this.payload.completeList.length / this.perPage)
    },
    updateList ({ clear } = {}) {
      if (clear) this.searchTerm = null
      this.$eventBus.$emit('loadList', {
        searchterm: this.searchTerm,
        conditions: this.conditions
      })
      // this.$eventBus.$emit('loadList', { searchterm: this.searchTerm || '', conditions: this.conditions })
    },
    makeSelection ({ iscancel } = {}) {
      // if (this.payload.isNotes || this.payload.isChecklist) { // notes
      //   this.selections = [this.payload.list[0]._id]
      //   return
      // }
      // if (this.payload.isTickets) { // tickets
      //   this.selectedTickets = [this.payload.list[0]]
      //   return
      // }
      // const firstItem = this.payload.list.find(({ accesscontrol }) => accesscontrol && accesscontrol.delete)
      // if (firstItem) this.selections = [firstItem._id]
      if (iscancel) {
        this.selections = []
        this.selectedTickets = []
      }
      this.selectionMode = !iscancel
    },
    pushOrPopSelection (index, isTickets) {
      if ((!isTickets) && (!this.payload.list[index].accesscontrol || !this.payload.list[index].accesscontrol.delete) && !this.payload.isNotes && !this.payload.isChecklist) {
        return
      }
      if (isTickets) {
        if (this.selectedTickets.includes(this.payload.list[index])) {
          const recordIndex = this.selectedTickets.indexOf(this.payload.list[index])
          this.selectedTickets.splice(recordIndex, 1)
        } else {
          this.selectedTickets.push(this.payload.list[index])
        }
      } else {
        if (this.selections.includes(this.payload.list[index]._id)) {
          const recordIndex = this.selections.indexOf(this.payload.list[index]._id)
          this.selections.splice(recordIndex, 1)
        } else {
          this.selections.push(this.payload.list[index]._id)
        }
      }
    },
    sortingHandler (index) {
      let sortingObj = { sortByField: 0, sortBy: 1 }
      if (this.payload.sortByField === index) sortingObj = { sortByField: index, sortBy: this.payload.sortBy === 1 ? -1 : 1 }
      else sortingObj = { sortByField: index, sortBy: 1 }
      this.$emit('listSortingEmitter', sortingObj)
    // },
    // deleteNotesRecords () {
    //   this.$eventBus.$emit('deleteItems', this.selections)
    },
    getTotalCount () {
      this.$emit('getCount', true)
    }
  },
  beforeDestroy () {
    this.$eventBus.$off('deleteItems')
    this.$eventBus.$off('getCount')
    this.$eventBus.$off('resetSelectedTickets')
    this.$eventBus.$off('changePage')
    this.$eventBus.$off('selectionModeToggle')
  }
}
</script>
<style>
.custom_list_style {
  background-color: #fff;
  /* border-color: #e8eaf6; */
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.6) !important;
  margin-top: 4px !important;
  min-height: 50px !important;
}
.custom-margin-auto {
  margin: auto !important;
}
.list_action_custom_margin {
  min-width: 35px !important;
}
.avatar_custom_margin, .list_action_custom_margin {
  margin: 0 !important;
}
.avatar_custom_margin {
  margin-right: 8px !important;
}
.custom_scroll_style {
  /* max-height: 84vh; */
  max-height: calc(100vh - 118px);
  overflow-y: auto;
  overflow-x: hidden;
}
.custom_scroll_style::-webkit-scrollbar {
  display: none !important;
}
.compactlist-page .v-toolbar__content {
  padding: 0 !important;
}
.custom-badge .v-badge__badge {
  min-width: 15px;
  width: 16px;
  height: 16px;
  padding: 2px 0;
  font-size: 11px;
  font-weight: 700;
  inset: auto auto calc(100% - 12px) calc(100% - 16px) !important;
}
.tickets-speed-dial div.v-speed-dial__list {
  align-items: flex-end !important;
}
.switch-list-btn {
  top: 50px !important;
  left: -8px !important;
}
</style>
